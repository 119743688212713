@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.footer {
    position: relative;
    width: 100%;
    background: #363636;
    height: auto; /* Adjusted to auto to allow for all content */
    padding-bottom: 50px; /* Added padding to prevent content from touching the footer's bottom */
}

.feedBack {
    position: absolute;
    bottom: 82.5%;
    left: 50%;
    transform: translate(-50%, 0);
    background: #595959;
    border-radius: 1rem;
    box-shadow: 0px 20px 43px 0px #0000001A, 0px 79px 79px 0px #00000017, 0px 178px 107px 0px #0000000D, 0px 316px 126px 0px #00000003, 0px 494px 138px 0px #00000000;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 69%;
    margin: 0 auto;
}

.fbTitle {
    font-size: 32px;
    font-weight: 700;
    color: #FDFDFD;
    margin: 0;
    text-align: left;
    font-family: Poppins;
}

.fbSubhead {
    font-size: 16px;
    font-weight: 400;
    color: #FDFDFD;
    margin-top: 8px;
    text-align: left;
    font-family: Poppins;
}

.feedbackPack {
    width: 100%;
    margin: auto;
}

form {
    width: 100%;
    margin-top: 12px;
}

form .inputs {
    display: flex;
    justify-content: space-between;
}

form .inputs input {
    width: 48%;
    padding: 16.5px 0px;
    border-radius: 6px;
    border: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
}
form .inputs input::placeholder {
    padding: 0 20px;
}

textarea:focus{
    padding: 16.5px 20px;
    border: none;
    outline: none;
}
textarea{
    resize: none;
    padding: 16.5px 0px;
    width:100%;
    height: 115px; 
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    font-family: Inter;
    margin-top: 17px;
    border-radius: 6px;
}
textarea::placeholder{
    padding: 0 20px;
   
}
input.submit {
    border: 1.5px solid #A81C1E;
    border-radius: 4px;
    padding: 12px 1.75rem;
    background: #A81C1E;
    color: #FDFDFD;
    margin-top: 24px;
    text-align: center;
}

/* Footer Content */
.footerCont {
    width: 77%;
    margin: 0 auto;
    padding-top: 165px;
    display: grid;
    grid-template-columns: 47% 14% 14%;
    gap: 10%;
    color: #FDFDFDB2;
}

.footLeft .footLogo {
    display: flex;
    gap: 43px;
    align-items: end;
}

.footLeft .footLogo img {
    width: 50%;
    height: 125px;
}

.footerContact {
    display: inline-block;
}

.footHeading {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.footHeading p {
    font-size: 1.25rem;
    font-weight: 800;
    line-height: 24px;
    text-align: left;
    margin: 0;
    font-family: "Manrope";
}

.footHeading div {
    width: 32px;
    height: 5px;
    background: #A81C1E;
}

.footList {
    font-size: 15px;
    font-weight: 500;
    line-height: 26.25px;
    text-align: left;
}

.footList ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-family: "Inter";
}

.copyright {
    width: 88.3%;
    height: 48.25px;
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    border-top: 1px solid #6A6A6ABF;
    font-family: "Inter";
    font-size: 1rem;
    font-weight: 400;
    line-height: 28px;
    color: #6A6A6A;
    margin: 32px auto;
    padding: 0;
}

.copyright p {
    margin-bottom: 0;
    padding: 0;
}
/* Media Queries for Small Screens */
@media (max-width: 768px) {
    .footerCont {
        grid-template-columns: 1fr;
        gap: 20px;
        padding-top: 40px;
    }

    .feedBack {
        position: relative;
        margin-top: 20px;
        width: 90%; /* Adjust width */
        margin-left: auto;
        margin-right: auto;
        height: auto;
        padding: 20px;
    }

    .fbTitle,
    .fbSubhead {
        text-align: center;
    }

    form .inputs {
        flex-direction: column;
        gap: 10px;
    }

    form .inputs input,
    textarea {
        width: 100%;
        margin: 10px auto; /* Center align inputs */
    }

    .footerCont {
        padding-top: 20px;
    }

    .footLeft .footLogo {
        gap: 15px;
        align-items: center;
        justify-content: center;
    }

    .footLeft .footLogo img {
        width: 40%;
        height: 100px;
    }

    .footList ul {
        gap: 10px;
    }

    .copyright {
        flex-direction: column;
        text-align: center;
    }
}