/* General Banner Styles */
.detailsBanner {
    width: 100%;
    background: #F5F5F5;
    border-radius: 12px;
}
.detailsBanner > div {
    width: 95%;
    margin: auto auto;
}

/* Title Styling */
.detailsTitle h4 {
    font-family: Poppins, sans-serif;
    font-size: 2rem; /* Adjusted for better scaling */
    font-weight: 600;
    line-height: 2.5rem;
    text-align: left;
    margin: 0;
    padding-top: 32px;
    padding-bottom: 26px;
    text-transform: capitalize;
}

/* Image Styling */
.detailsImg {
    width: 100%;
    height: auto;
    max-height: 598px;
    object-fit: cover;
    object-position: center;
    border-radius: 12px;
    image-rendering: high-quality;
}

/* Meta Data Styling */
.metaData {
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    
}
.metaData p {
    display: inline-block;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #3E3232BF;
    width: 45%;
    margin: 10px 0;
}

/* Body Text Styling */
pre.detailsBody {
    width: 97.5%;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75rem;
    text-align: left;
    color: #3E3232;
    padding: 16px;
    border-radius: 8px;
    overflow-x: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    height: auto;
    margin: 16px 0;
}

/* Comments Section */
.commentsSection h3 {
    font-family: Poppins, sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
}
.commentsList {
    list-style: none;
    padding: 0;
    margin: 0;
}
.comment {
    background-color: #FFFFFF;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 15px;
}
.comment p {
    font-size: 0.75rem;
    color: #3E3232;
    margin: 4px 0;
}
.commentDate {
    font-size: 0.65rem;
    color: #888;
    padding-left: 0.2%;
}
.commentContent {
    font-size: 1rem !important;
}

/* Comment Form Styling */
.commentForm {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
}

.commentForm input[type="text"],
.commentForm textarea {
    width: 100%;
    max-width: 600px;
    padding: 12px;
    font-size: 0.95rem;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    transition: border-color 0.3s ease;
}

.commentForm input[type="text"] {
    max-width: 250px;
}

.commentForm textarea {
    min-height: 100px;
    resize: vertical;
}

.commentForm input:focus,
.commentForm textarea:focus {
    border-color: #A81C1E;
}
.commentForm button {
    font-size: 1rem;
    font-weight: 400;
    font-family: Poppins, sans-serif;
    color: #FFFFFF;
    background-color: #A81C1E;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: flex-start;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .detailsImg {
        height: auto;
        max-height: 400px;
        object-position: center;
    }
    .detailsTitle h4 {
        font-size: 1.5rem;
        line-height: 2rem;
    }
    .metaData p {
        width: 100%;
        font-size: 0.75rem;
        line-height: 18px;
    }
    pre.detailsBody {
        font-size: 0.95rem;
        line-height: 1.5rem;
    }
    .commentForm input[type="text"],
    .commentForm textarea {
        width: 90%;
        max-width: 100%;
        margin:auto;
        resize: none;
        
    }

}

@media (max-width: 480px) {
    pre.detailsBody {
        padding: 4px;
    }
    .detailsTitle h4 {
        font-size: 1.25rem;
        padding-top: 16px;
        padding-bottom: 16px;
    }
    .metaData {
        flex-direction: column;
        align-items: center;
    }
    .commentForm button {
        width: 50%;
        margin: auto;
    }
}
