/* Navbar Styles */
.navbar {
    margin-bottom: 18px;
    margin-top: 16px;
  }
  
  .nav {
    height: 69px;
    display: flex;
    justify-content: space-between;
    align-items: center; /* Ensure the logo and links are aligned properly */
  }
  
  .navLogo {
    width: 236px;
    height: 69px;
  }
  
  .navLinks {
    display: flex;
    list-style-type: none;
    gap: 1.5rem;
    font-size: 1rem;
    font-weight: 500;
    color: #363636;
    align-items: center; /* Align links properly */
  }
  
  /* Style the hamburger menu */
  .menuToggle {
    display: none; /* Hide on desktop */
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 10;
  }
  
  /* Mobile View */
  @media (max-width: 768px) {
    .navLinks {
      display: none; /* Hide links by default on mobile */
      flex-direction: column;
      width: 100%;
      background-color: white; /* Give a background color */
      position: absolute; /* Position below the navbar */
      top: 69px; /* Position the menu just below the navbar */
      left: 0;
      z-index: 9;
    }
  
    .navLinks.active {
        margin-top: 30px;
        padding: 30px 0;
      display: flex; /* Show the links when menu is active */
    }
  
    .menuToggle {
      display: block; /* Show hamburger icon */
    }
  
    /* Optional: Adjust navbar height when menu is open */
    .nav {
      height: auto;
    }
  }
  