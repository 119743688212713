/* Card Container */
.card {
    height: 418px;
    padding: 8px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 4px 28px -2px #00000014;
    transition: height 0.3s ease-in-out;
}

/* Card Image */
.cardImg {
    width: 100%;
    height: 240px;
    border-radius: inherit;
    object-fit: cover;
}

/* Card Body */
.cardBody {
    display: flex;
    flex-direction: column;
    gap: 6px;
    color: #6A6A6ABF;
    width: 100%;
    height: 186px;
    cursor: pointer;
}

/* Tag */
.cardBody > span {
    width: max-content;
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 16px;
}

/* Date */
.cardDate {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 20px;
    text-transform: capitalize;
}

/* Card Title */
.cardHead {
    font-size: 1rem;
    font-weight: 600;
    line-height: 24px;
    color: #363636;
}

/* Card Snippet */
.cardSnippet {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Remove margin from paragraph */
.cardBody > p {
    margin: 0;
}

/* RESPONSIVE STYLES */

/* Mobile (max-width: 600px) */
@media (max-width: 600px) {
    .card {
        height: 320px;
        padding: 6px;
    }

    .cardImg {
        height: 180px;
    }

    .cardBody {
        height: 140px;
    }

    .cardBody > span {
        font-size: 0.5rem;
    }

    .cardDate {
        font-size: 0.625rem;
    }

    .cardHead {
        font-size: 0.875rem;
        line-height: 20px;
    }

    .cardSnippet {
        font-size: 0.75rem;
        line-height: 18px;
    }
}

/* Tablet (min-width: 601px and max-width: 900px) */
@media (min-width: 601px) and (max-width: 900px) {
    .card {
        height: 380px;
        padding: 8px;
    }

    .cardImg {
        height: 220px;
    }

    .cardBody {
        height: 160px;
    }

    .cardBody > span {
        font-size: 0.55rem;
    }

    .cardDate {
        font-size: 0.7rem;
    }

    .cardHead {
        font-size: 0.95rem;
    }

    .cardSnippet {
        font-size: 0.8rem;
    }
}

/* Desktop (min-width: 901px and max-width: 1200px) */
@media (min-width: 901px) and (max-width: 1200px) {
    .card {
        height: 418px;
    }

    .cardImg {
        height: 240px;
    }

    .cardBody {
        height: 186px;
    }

    .cardBody > span {
        font-size: 0.625rem;
    }

    .cardDate {
        font-size: 0.75rem;
    }

    .cardHead {
        font-size: 1rem;
    }

    .cardSnippet {
        font-size: 0.875rem;
    }
}

/* Large Desktop (min-width: 1201px) */
@media (min-width: 1201px) {
    .card {
        height: 418px;
    }

    .cardImg {
        height: 240px;
    }

    .cardBody {
        height: 186px;
    }

    .cardBody > span {
        font-size: 0.625rem;
    }

    .cardDate {
        font-size: 0.75rem;
    }

    .cardHead {
        font-size: 1rem;
    }

    .cardSnippet {
        font-size: 0.875rem;
    }
}
