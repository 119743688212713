.filter{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 49px auto;

}
.allNews{
    color: #A81C1E;
    background:#F5F5F5;
    padding: 10px 15px;
    text-align: center;
    border-bottom: 5px solid#A81C1E;
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 500;
    line-height: 24px;
}
.filterParameters{
    display: flex;
    width: 59.75%;
    gap: 5%;
}
.selection{
    width: 48%;
    display: flex;
    justify-content: space-between;
    font-family: Poppins;
    font-size: 0.875rem;
    line-height: 21px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}
.selection label{
    width: fit-content;
    align-self:center;
    font-weight: 500;
    color: #3E3232;

}
.selection select{
    border: none;
    align-self:center;
    width: 75%;
    padding: 5%;
    border-radius: 0.65rem;
    background: #F5F5F5;
    font-weight: 600;
    cursor: pointer;
}
.selection select:focus{
    border: none;
    outline: none;
}
.searchBar{
    width: 50%;
    
}
.searchBar input{
    width: 90%;
    padding: 5%;
    border-radius: 0.65rem;
    background: #F5F5F5;
    font-weight: 600;
    align-self: center;
    border: none;
    font-weight: 400;
    font-size: 0.875rem;
}
.searchBar input::placeholder{
    font-style: italic;
}

.newsList{
    display: grid;
    grid-template-columns: 31.3% 31.3% 31.3%;
    gap: 2.5%;
    margin: 0 auto;
    column-gap: 2.5%;
    row-gap: 30px;
}

/* Mobile (max-width: 600px) */
@media (max-width: 600px) {
    .filter{
        width: 90%;
        display: block;
        padding: 12px;
        margin: 10px auto;
    }
    .filterParameters{
        display: block;
        width: 100%;
    }
    .selection{
        margin-top: 20px;
        width: 48%;
        display: inline-block;
        justify-content:left;
    }
    .selection label{
        width: 100%;    
    }
    .searchBar{
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .newsList{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2.5%;
        margin: 0 auto;
        column-gap: 2.5%;
        row-gap: 30px;
    }
}


