/* Marquee Styles */
.marquee {
    width: 100%;
    padding: 8px 0;
    background: #A91B1E;
    overflow: hidden;
    color: #FDFDFD;
    text-align: center;
    align-items: center;
  }
  
  .marqueeTxt {
    padding: 0;
    margin: 0;
    white-space: nowrap; /* Prevent text from wrapping */
    display: inline-block; /* Ensure the text stays in one line */
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    animation: slide 20s linear infinite;
  }
  
  /* Keyframe Animation */
  @keyframes slide {
    0% {
      transform: translateX(100%); /* Start from right */
    }
    100% {
      transform: translateX(-100%); /* Move to the left */
    }
  }
  
  /* Optional: Adjust text size for mobile view */
  @media (max-width: 600px) {
    .marqueeTxt {
      font-size: 14px;
      margin-right: -100%; /* Reduce margin for smaller screens */
    }
  }
  