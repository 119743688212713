/* Breadcrumb Styles */
.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 24px 34px;
    gap: 8px;
    align-items: center;
    font-size: 0.875rem;
    line-height: 24px;
    color: #363636;
    font-family: 'Poppins', sans-serif;
}

.breadcrumb-item {
    display: flex;
    align-items: center;
}

.breadcrumb-item a {
    text-decoration: none;
    color: #A81C1E;
    font-weight: 700;
    transition: color 0.3s ease;
}

.breadcrumb-item a:hover {
    color: #910e10;
    text-decoration: underline;
}

/* Separator Styling */
.breadcrumb-item::after {
    content: "›";
    margin: 0 8px;
    color: #888;
}

.breadcrumb-item:last-child::after {
    content: "";
}

/* Active Breadcrumb Item */
.breadcrumb-item.active {
    color: #555;
    font-weight: 600;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .breadcrumb {
        gap: 4px;
    }
    .breadcrumb-item::after {
        margin: 0 4px;
    }
}

@media (max-width: 480px) {
    .breadcrumb {
        opacity: 0;
        height: 0px;
    }
    .breadcrumb-item {
        margin-bottom: 0;
    }
}
