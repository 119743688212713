.oNews {
    height: 782px;
    overflow: hidden;
}

.oNews > nav {
    width: 88%;
    height: auto;
    margin-top: 24px;
    margin-bottom: 44px;
}

.oNews > nav > ul {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    overflow-x: auto;
    white-space: nowrap;
}

.oNewsLink {
    font-size: 1rem;
    font-weight: 500;
    line-height: 24px;
    padding: 8px;
    height: inherit;
    cursor: pointer;
    margin-right: 1rem;
}

.oNewsLinkActive {
    color: #A81C1E;
    border-bottom: 2px solid #A81C1E;
}

.otherNewsCards {
    display: grid;
    grid-template-columns: 47.75% 47.75%;
    gap: 32px;
    overflow: hidden;
}

/* Card Styles */
.oNewsCard {
    display: flex;
    width: auto;
    height: 200px;
    border-radius: 12px;
    gap: 10px;
    padding: 10px;
    box-shadow: 0px 4px 28px -2px #00000014;
    background: #FDFDFD;
}

.oNewsCard > img {
    width: 33.15%;
    height: 190px;
    border-radius: 0.75rem;
    object-fit: cover;
}

.oNewsCard div {
    width: 59.7%;
    height: auto;
    color: #6A6A6A;
    cursor: pointer;
}

.oNewsTitle {
    font-size: 1rem;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #3E3232;
}

.oNewsSnippet {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.oNewsDate {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .oNews > nav > ul {
        display: flex;
        gap: 0.5rem;
        padding: 0;
        margin: 0;
        overflow-x: auto;
        white-space: nowrap;
        scrollbar-width: none;
        scroll-behavior: smooth;
    }

    .oNewsLink {
        padding: 12px;
        text-align: center;
    }

    .otherNewsCards {
        grid-template-columns: 48% 48%;
        gap: 10px;
    }

    .oNewsCard {
        flex-direction: column;
        height: auto;
    }

    .oNewsCard > img {
        width: 100%; /* Image takes full width of the card */
        height: 100px; /* Set a definite height for the image in mobile view */
        object-fit: cover;
    }

    .oNewsCard div {
        width: 100%;
        padding-top: 10px;
    }
}
