.adminCard {
    height: auto;
}
#cardBody {
    height: auto;
}
.ctaBtns {
    display: flex;
    justify-content: space-around;
}
.ctaBtns button {
    margin-top: 10px;
    padding: 0;
    border: none;
    background: inherit;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;

}

