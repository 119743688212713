.loginForm{
    width: 59.83%;
    margin: 70px auto;
    /* margin-top: 70px; */
}
.loginForm > div {
    margin: 32px auto;
}
.loginForm > div label{
    display: block;
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #3E3232;
    margin: 2px;
}
.loginForm > div input{
    width: 100%;
    height: 48px;
    border-radius: 12px;
    background: #F5F5F5;
    border: none;
}
.loginForm .loginBtn{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #FDFDFD;
    background: #A81C1E;
    padding: 12px 28px;
    border: 0;
    margin: auto auto;
    margin-top: 40px;
}