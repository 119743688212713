.blah {
    position: relative;
    height: 409px;
    margin-top: 41px;
    color: #FFFFFF;
}

.overlay {
    position: absolute;
    width: 97.4%;
    height: inherit;
    background: #0000003d;
}

.sliderBody {
    width: 100%;
    height: 409px;
    background-position: center;
    background-size: cover;
    border-radius: 0.3125rem;
    background-repeat: no-repeat;
}

.carTag {
    background: #FFFFFFB2;
    color: #363636;
    font-family: Poppins;
    font-size: 0.625rem;
    font-weight: 700;
    line-height: 16px;
    text-transform: capitalize;
}

.carouselTxt {
    height: 248px;
    width: 64.583%;
    position: absolute;
    overflow-y: hidden;
    top: 123px;
    left: 11.5%;
}

.carouselTxt p {
    margin: 0;
}

.carPack {
    width: 100%;
    gap: 8px;
    display: flex;
    flex-direction: column;
}

.carouselBtn {
    margin-top: 4.6%;
    background: transparent;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
}

.carBody {
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

    display: -webkit-box; /*important*/
    -webkit-line-clamp: 3; /* Limits to 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 3; /* can also be the number of lines you want to show */
}

.leftArrow {
    position: absolute;
    top: 37.5%;
    left: 5%;
    font-size: 1rem;
    font-weight: 600;
    color: #334155;
    z-index: 1;
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 6.25rem;
    background: #FFFFFF;
    text-align: center;
}

.rightArrow {
    position: absolute;
    top: 37.5%;
    right: 8.9%;
    font-size: 1rem;
    font-weight: 800;
    color: #334155;
    z-index: 1;
    cursor: pointer;
    width: 1.5rem;
    height: 24px;
    border-radius: 6.25rem;
    background: #FFFFFF;
    text-align: center;
}

.indicatorsPack {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 2%;
    left: 45%;
    gap: 4px;
}

.indicators {
    width: 12px;
    height: 3px;
    border-radius: 100px;
    background: #615d70;
    cursor: pointer;
}

.indicator-active {
    background: white;
}

/* Media Queries for Small Screens (max-width: 768px) */
@media (max-width: 768px) {
    .blah {
        height: max-content; /* Adjust the height for mobile */
        margin-top: 20px; /* Adjust margin */
    }
    .overlay{
        height: 100%;
        width: 96.4%;
    }

    .sliderBody {
        height: 250px; /* Reduce height for mobile */
    }

    .carouselTxt {
        width: 80%; /* Make text area wider on mobile */
        left: 10%;
        top: 10px; /* Adjust position for smaller height */
        height: auto; /* Allow content to fit */
    }

    .carouselTxt p {
        font-size: 0.9rem; /* Reduce text size */
    }

    .carTag {
        font-size: 0.5rem; /* Reduce font size for mobile */
    }

    .carBody {
        font-size: 0.9rem; /* Smaller font size */
    }

    .carouselBtn {
        font-size: 0.9rem; /* Smaller button */
    }

    .leftArrow, .rightArrow {
        display: none; /* Hide arrows on mobile */
    }

    .indicatorsPack {
        bottom: 10px; /* Adjust indicator position */
        left: 50%; /* Center indicators */
        transform: translateX(-50%);
        display: none;
    }

    .indicators {
        width: 10px; /* Smaller indicator */
        height: 3px;
    }
}
