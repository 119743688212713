.videoGallery {
    display: flex;
    width: 100%;
    gap: 1.8%;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

/* Large screen styles (default) */
.video-card-style-1 {
    width: 687px;
    height: 444px;
    position: relative;
}

.video-frame-1 {
    height: 100%;
    width: inherit;
    border-radius: 12px;
}

.video-card-style-1 > .videoTxt {
    backdrop-filter: blur(10px);
    background: #ffffff86;
    border-radius: 12px;
    width: 92.5%;
    height: 32.43%;
    top: 58.4%;
    left: 1.5%;
    margin: auto auto;
    position: absolute;
    padding: 1rem;
}

.videoTxt h3 {
    margin: 0;
}

.videoTxt p {
    color: #000000BF;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.videoTxt > span {
    color: #6A6A6ABF;
}

/* Side video cards (2nd and 3rd) */
.videoSide {
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: 5%;
}

/* Second video */
.video-card-style-side {
    display: flex;
    height: 190px;
    width: 100%;
    box-shadow: 0px 4px 28px -2px #00000014;
    background: #FDFDFD;
    padding: 10px;
    border-radius: 12px;
}

.video-card-style-side > iframe {
    display: flex;
    height: inherit;
    width: 48%;
    border-radius: 12px;
}

.video-card-style-side > .videoTxt {
    width: 100%;
    padding: 2%;
}

/* Third video */
.video-card-style-3 {
    display: flex;
    height: 190px;
    width: 39.1%;
    box-shadow: 0px 4px 28px -2px #00000014;
    background: #FDFDFD;
    padding: 10px;
    border-radius: 12px;
}

.video-card-style-3 > iframe {
    display: flex;
    height: inherit;
    width: 40.5%;
    border-radius: 12px;
}

.video-card-style-3 > .videoTxt {
    padding: 2%;
}

.videoLink {
    padding: inherit;
    margin: inherit;
}

.videoCard:active .videoTxt {
    display: none;
}

.videoCard:focus .videoTxt {
    display: none;
}

/* Media Queries for Small Screens (max-width: 768px) */
@media (max-width: 768px) {
    /* Adjust video gallery to show only one video */
    .videoGallery {
        flex-direction: column; /* Stack videos vertically */
        gap: 20px; /* Adjust spacing between the video cards */
    }

    /* Hide second and third video cards on small screens */
    .video-card-style-side,
    .video-card-style-3 {
        display: none;
    }

    /* Adjust main video (first card) */
    .video-card-style-1 {
        width: 100%; /* Take full width */
        height: 300px; /* Adjust height */
    }

    .video-frame-1 {
        height: 100%;
        width: 100%;
    }

    .video-card-style-1 > .videoTxt {
        top: 60%;
        width: 100%;
        padding: 10px;
    }

    /* Adjust the card text */
    .videoTxt p {
        font-size: 14px; /* Smaller text for mobile */
    }
}
