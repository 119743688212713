.Abouting {
    padding-bottom: 35%;
}

.aboutCont {
    width: 74.83%;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 70px;
}

.aboutIntro {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.aboutIntro > img {
    width: 46%;
    display: flex;
    object-fit: cover;
}

.aboutIntro > div {
    width: 48.9%;
}

.aboutIntro > div h4 {
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    width: 88.3%;
    margin-bottom: 0;
}

.aboutIntro > div p {
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 400;
    line-height: 34px;
    text-align: left;
    margin-top: 16px;
    margin-bottom: 0;
}

.aboutOffer {
    width: 93.87%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2%; /* Add the gap between left and right flex items */
}

.aboutOffer img {
    width: 45.67%;
    height: 915px;
    border-radius: 25px;
}

.aboutOffer > div {
    align-self: flex-end;
    width: 49.34%;
    height: 780px;
}

.aboutHeading {
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 700;
    line-height: 48px;
    text-align: left;
}

.aboutCard {
    background: #F5F5F5;
    width: 84.5%;
    padding: 6%;
    border-radius: 16px;
    margin: 16px 0;
}

.aboutCard h5 {
    font-family: Poppins;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    margin: 0;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.aboutCard p {
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 400;
    line-height: 34px;
    text-align: left;
    display: none;
    margin-bottom: 0;
}

.cardActive {
    display: block !important;
    transition: 1ms ease-in;
    transition-duration: 1ms;
}

.aboutJoin > h4 {
    margin-bottom: 5px;
}

.aboutJoin span {
    width: 17.5%;
    height: 0px;
    border: 3px solid #A81C1E;
    background: #A81C1E;
    display: block;
    margin-bottom: 24px;
}

.aboutJoin p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
    text-align: left;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .aboutCont {
        width: 90%;
    }

    .aboutIntro {
        flex-direction: column;
        align-items: center;
    }

    .aboutIntro > img {
        width: 100%;
        margin-bottom: 16px;
    }

    .aboutIntro > div {
        width: 100%;
    }

    .aboutOffer {
        flex-direction: column;
    }

    .aboutOffer img {
        width: 100%;
        height: auto;
        margin-bottom: 16px;
    }

    .aboutOffer > div {
        width: 100%;
        height: auto;
    }

    .aboutHeading {
        font-size: 1.75rem;
    }

    .aboutCard {
        width: 100%;
    }

    .aboutCard h5 {
        font-size: 1rem;
    }

    .aboutCard p {
        font-size: 0.95rem;
    }

    .aboutJoin p {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .Abouting {
        padding-bottom: 0;
    }
    .aboutCont {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 40px;
    }

    .aboutIntro {
        flex-direction: column;
        align-items: center;
    }

    .aboutIntro > img {
        width: 80%;
        margin-bottom: 16px;
    }

    .aboutIntro > div {
        width: 90%;
        text-align: center;
    }

    .aboutOffer {
        flex-direction: column;
        align-items: center;
    }

    .aboutOffer img {
        width: 100%;
        height: auto;
        margin-bottom: 16px;
        display: none;
    }

    .aboutOffer > div {
        width: 100%;
        height: auto;
    }

    .aboutHeading {
        font-size: 1.5rem;
        text-align: center;
    }

    .aboutCard {
        width: 100%;
        padding: 5%;
    }

    .aboutCard h5 {
        font-size: 1rem;
    }

    .aboutCard p {
        font-size: 0.9rem;
    }

    .aboutJoin p {
        font-size: 12px;
        line-height: 28px;
        text-align: center;
    }
}

/* Ensure the 'Join Us' section has enough bottom space to avoid overlap */
.aboutJoin {
    margin-bottom: 100px; /* Add margin-bottom to avoid footer overlap */
}
