/* General Styles */
.catchUp {
    margin-top: 43px;
    margin-bottom: 36px;
}

.headlines {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;
}

.headlines > div > p {
    margin: 0;
}

.headlines > button {
    height: 44px;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 20px;
    max-width: 9.9375rem;
}

.headlinesCard {
    display: grid;
    grid-template-columns: 31.3% 31.3% 31.3%;
    gap: 2.5%;
    margin: 0 auto;
    column-gap: 2.5%;
    row-gap: 30px;
}

.viewMore {
    width: 103%;
}

/* VIDEOS SECTION */
.videos {
    background: #F5F5F5;
    height: 538px;
    padding-top: 55px;
    padding-bottom: 58px;
    width: 100%;
}

/* LATEST STORIES */
.latest {
    display: grid;
    grid-template-columns: 65.8% 32%;
    gap: 2.2%;
    padding-top: 52px;
}

.latest > section {
    height: inherit;
}

.topStoriesCard {
    display: grid;
    grid-template-columns: 48.1% 48.1%;
    gap: 2.5%;
    column-gap: 4%;
    row-gap: 20px;
}

.latestBanner {
    position: relative;
}

.latestBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
}

.latestBanner button {
    width: 57.8%;
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, 0);
    border: none;
}

.icon {
    margin-left: 5%;
}

/* OTHER NEWS SECTION */
.otherNews {
    padding-top: 80px;
    margin-bottom: 450px;
}

.otherNews button {
    margin: 86px auto;
}

/* ADVERT */
.job{
    padding: 4% 0;
}
.advert{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1%;
}
.advert img{
   object-fit: contain;
   width: 100%;
}


/* RESPONSIVE STYLES */

/* Mobile (max-width: 600px) */
@media (max-width: 600px) {
    .headlines {
       flex-direction: column;
        align-items: flex-start;
        gap: 5px;
    }

    .headlinesCard {
        grid-template-columns: 100%;
        /* grid-template-columns: 100%; */
        gap: 20px;
    }

    .videos {
        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .latest {
        display: block;
        padding-top: 30px;
    }

    .topStoriesCard {
        grid-template-columns: 100%;
        gap: 20px;
    }

    .latestBanner button {
        width: 50%;
        top: 90%;
        margin: 15px 0;
    }

    .latestBanner img {
       display: none;
    }
    .otherNews {
        margin-bottom: 50px;
    }

    .viewMore {
        width: 120%;
    }
    .otherNews button {
        margin: 46px auto;
    }
}

/* Tablet (min-width: 601px and max-width: 900px) */
@media (min-width: 601px) and (max-width: 900px) {
    .headlinesCard {
        grid-template-columns: 48% 48%;
        gap: 4%;
    }

    .latest {
        grid-template-columns: 60% 38%;
        gap: 2%;
    }

    .topStoriesCard {
        grid-template-columns: 48% 48%;
        gap: 20px;
    }

    .videos {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .otherNews {
        margin-bottom: 250px;
    }
    .otherNews button {
        margin: 56px auto;
    }
}

/* Desktop (min-width: 901px and max-width: 1200px) */
@media (min-width: 901px) and (max-width: 1200px) {
    .headlinesCard {
        grid-template-columns: 31.3% 31.3% 31.3%;
    }

    .latest {
        grid-template-columns: 65.8% 32%;
    }

    .topStoriesCard {
        grid-template-columns: 48.1% 48.1%;
    }

    .otherNews {
        margin-bottom: 350px;
    }
}

/* Large Desktop (min-width: 1201px) */
@media (min-width: 1201px) {
    .headlinesCard {
        grid-template-columns: 31.3% 31.3% 31.3%;
    }

    .latest {
        grid-template-columns: 65.8% 32%;
    }

    .topStoriesCard {
        grid-template-columns: 48.1% 48.1%;
    }

    .otherNews {
        margin-bottom: 450px;
    }
}
