.dropzone {
    border: 2px dashed #cccccc;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    height: 598px;
}
.dropzone p {
    margin: 25% auto;
}

.thumbnailPreview {
    max-width: 100%;
    max-height: 598px;
    border-radius: 10px;
    object-fit: cover;
}
.ql-editor {
    height: 500px;
    overflow-y: scroll;
}
