/* Popup Overlay */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Overlay background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Popup Content */
.popup-content {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    width: 30%;
    text-align: center;
}

.popup-content h3 {
    margin-bottom: 0px;
    font-size: 18px;
}
.popup-content p {
    margin-top: 10px;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 14px;
    color: rgb(185, 97, 14);
}

.popup-buttons {
    display: flex;
    justify-content: space-around;
}

.btn {
    padding: 10px 40px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.yes {
    background-color: #4CAF50;
    color: white;
}

.no {
    background-color: #f44336;
    color: white;
}

.yes:hover {
    background-color: #45a049;
}

.no:hover {
    background-color: #e53935;
}
