/* src/components/Loader.css */
.loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full screen height */
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* Slightly transparent background */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999; /* Ensure it appears above other content */
  }
  
  .loader-gif {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  
  .loader-container p {
    font-family: Poppins, sans-serif;
    font-size: 1.125rem;
    color: #3E3232;
    margin-top: 10px;
  }
  