/* General Styles */
.App {
  /* Full-width layout */
  width: 100%;
  overflow: hidden;
  
}

a {
  text-decoration: none !important;
  color: inherit;
  margin: 0;
  padding: 0;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

/* Container */
.container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 0 1rem;
}

/* Heading */
.heading {
  font-size: 1.5rem;
  line-height: 36px;
  font-weight: 700;
}

/* Button */
button {
  border: 1.5px solid #363636;
  border-radius: 57px;
  padding: 12px 1.75rem;
  align-items: center;
  display: flex;
  justify-content: center;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

/* Tag */
.tag {
  border-radius: 28px;
  padding: 2px 0.625rem;
  background: #A81C1E1A;
  color: #A81C1E;
  text-transform: capitalize;
}

/* UsenVoice Text */
.usenVoice {
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  color: #A81C1E;
}

/* RESPONSIVE STYLES */

/* Mobile (max-width: 600px) */
@media (max-width: 600px) {
  .container {
    width: 99%;
    margin: auto auto;
    padding: 0 0.2rem;
  }

  .heading {
    font-size: 1.25rem;
    line-height: 28px;
  }

  button {
    padding: 10px 1.25rem;
    font-size: 0.875rem;
  }

  .tag {
    padding: 2px 0.5rem;
    font-size: 0.75rem;
  }

  .usenVoice {
    font-size: 0.875rem;
    line-height: 20px;
  }
}

/* Tablet (min-width: 601px and max-width: 900px) */
@media (min-width: 601px) and (max-width: 900px) {
  .container {
    max-width: 90%;
  }

  .heading {
    font-size: 1.375rem;
    line-height: 32px;
  }

  button {
    padding: 11px 1.5rem;
    font-size: 0.9375rem;
  }

  .tag {
    padding: 2px 0.6rem;
    font-size: 0.8125rem;
  }

  .usenVoice {
    font-size: 0.9375rem;
    line-height: 22px;
  }
}

/* Desktop (min-width: 901px and max-width: 1200px) */
@media (min-width: 901px) and (max-width: 1200px) {
  .container {
    max-width: 95%;
  }

  .heading {
    font-size: 1.5rem;
    line-height: 36px;
  }

  button {
    padding: 12px 1.75rem;
    font-size: 1rem;
  }

  .tag {
    padding: 2px 0.625rem;
  }

  .usenVoice {
    font-size: 1rem;
  }
}

/* Large Desktop (min-width: 1201px) */
@media (min-width: 1201px) {
  .container {
    width: 95%;
  }

  .heading {
    font-size: 1.5rem;
  }

  button {
    padding: 12px 1.75rem;
  }

  .tag {
    padding: 2px 0.625rem;
  }

  .usenVoice {
    font-size: 1rem;
  }
}
