
.newsBanner{
    height: 154px;
    width: inherit;
    position: relative;
}
.newsBannerImg{
    height: 100%;
    width: 100%;
    border-radius: 24px;
    object-fit: cover;
}
.newsBannerTxt{
    position: absolute;
    top:50%; 
    left: 50%;
    transform: translate(-50%, -50%); /* centering an absolutly positioned element*/
}
.newsBannerTxt > div{
    width: fit-content;
    background: #A81C1E;
    color: #FDFDFD;
    font-size: 0.625rem;
    font-weight: 700;
    line-height: 16px;
    margin: auto auto;

}
.newsBannerTxt h3{
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 60px;
    text-align: left;
    color: white;
    margin: 0;
}

.newsItem{
    margin-top:38px;
    margin-bottom:62px;
}
.pages{
    display: flex;
    justify-content: space-evenly;
    width: 21%;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center; 
    color: #3E3232;
    align-items: center;
    margin: auto auto;
}
.pages span{
    display: flex;
    justify-content: space-evenly;
    width: 21%;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center; 
    color: #3E3232;
    align-items: center;
}
.paginationBtn{
    border-radius: 0;
    padding: 10px 16px;
    border: none;
    border-radius: 12px;
}
.noNewsMessage {
    text-align: center;
    font-size: 1rem;
    width: max-content;
    margin: auto;
}



@media (max-width: 600px) {
    .pages{
        width: 51%;
    }
    .newsBannerTxt{
        width: 100%;
    }
    .newsBannerTxt h3{
        text-align: center;
    }
    .noNewsMessage {
        font-size: 1.2rem;
        color: #333;

    }
}
  
  /* Tablet (min-width: 601px and max-width: 900px) */
@media (min-width: 601px) and (max-width: 900px) {
    .pages{
        width: 51%;
    }
}
  
  /* Desktop (min-width: 901px and max-width: 1200px) */
@media (min-width: 901px) and (max-width: 1200px) {
    .pages{
        width: 31%;
    }
    .noNewsMessage {
        font-size: 1.5rem;
        color: #000;
    }
}
  