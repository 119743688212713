.editPage label{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    display: block;
    margin-bottom: 10px;
}
.editPage button{
    margin: auto auto;
    margin-bottom: 20px;
    background: #A81C1E;
    border: none;
    color:#FDFDFD;
}
.thumbnail{
    width: 100%;
}
.thumbnailImg{
    width: 100%;
    object-fit: cover;
}
.thumbnailImg img{
    width: 100%;
    object-fit: cover;
    height: 394px;
}
.changeImg {
   padding: 4px;
}
.changeImg input[type="file" i]  {
   padding: 4px;
}
.editTitle{
    width: 100%;
    margin: 20px auto;
}
.editTitle label{
    width: 100%;
    
}
.editTitle Input{
    width: 96%;
    border: none;
    padding: 2%;
    border-radius: 12px ;
    background: #F5F5F5;
    font-size: 2rem;
    
}
.editBody{
    margin-bottom: 30px;
}
.editCategory{
    margin-bottom: 30px;
}
.editCategory select{
    margin-bottom: 30px;
    border: none;
    padding: 1% 0;
    border-bottom: 4px solid #A81C1E;
    font-size: 1.5rem;
    outline: none;
}
.editCategory select>option{
    border: none;
    outline: none;
    padding: 5px;
    border-radius: 12px ;
    background: #F5F5F5;
    font-size: 1rem;
}
.ql-editor {
    height: 500px;
    overflow-y: scroll;
}

.ql-container.ql-snow{
    background: #F5F5F5;
    margin-top: 10px;
    border-bottom-right-radius:24px ;
    border-bottom-left-radius:24px ;
}
.ql-editor{
    font-family: Poppins;
    /* font-size: 16px; */
}